* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');


//* Extra small devices (phones, 600px and down) */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */

$cabin: 'Cabin', sans-serif;
$yellowtail: 'Yellowtail', cursive;
$kanit: 'Kanit', sans-serif;
$pt: 'PT Serif', serif;
$public: 'Public Sans', sans-serif;
$comforta: 'Comfortaa', cursive;
$patua: 'Patua One', cursive;
$red :'Red Hat Display', sans-serif;
$pixel : 'VT323', monospace;


//HEADER

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  transition: .3s ease-in;
  top: 0;
  z-index: 10;
}

.header-bg {
  transition:  .3s ease-out;
  top: -15%;

  @media only screen and (max-width: 959px){
    top: 0;
    background-color: white;
    }
}

.header-container {
  display: flex;
  width: 100%;
  max-width: 1230px;
  margin: auto;
  justify-content: space-between;
}

.header-left-container {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hamburger-react {
  display: none;

  
  @media only screen and (max-width: 768px){
    display: block;
    z-index: 3;
  }
}

.header-solo {
  background-color: #e57c23;
  align-items: center;
  display: flex;
  padding: 0 20px;
  text-decoration: none;
  font-family: $cabin;
  box-shadow: 8px 8px 0px -3px #4f5d75;
  transition: .3s ease-in;
  color: white;
  font-size: 18px;

  @media only screen and (max-width: 768px){
    display: none;
  }
}


.header-solo-bg {
  background-color: white;
  color: black;
  transition: .3s ease-out;
}

.logo-image {
  height: 50px;
  transition: .3s ease-out;

}

.logo-image-color {
  transition: .3s ease-out;
}

.menu-container {
  font-size: 18px;
  font-family: $cabin;
  display: flex;

}

.menu-mobile-container {
  display: none;
  transition: .3s ease-out;
  overflow: hidden;

  
  @media only screen and (max-width: 959px){
    display:flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    top: 0;
    z-index: 2;
    height: 100vh;
    right: -100%;

    &--clicked {
      background-color: rgba(255, 255, 255, 1 );
      right: 0;
      width: 65%;
      transition: .3s ease-in; 
    }
  }
}

.menu-mobile {
  font-size: 30px;
  margin: 10px 0;
  text-decoration: none;
  color: #4F5D75 ;
  font-family: $public;
}

.menu-selection {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  text-decoration: none;
  color: black;
  transition: color .3s ease-in;

  @media only screen and (max-width: 959px){
    display: none;
  }
}


.menu-selection-color {
  color: white;
  transition: color .3s ease-out;

}

.menu-selection-solo {
  padding: 10px 0;
  text-decoration: none;
  color: white;
}

.menu-selection:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu-selection::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

//LINKS

.links {
  position: fixed;
  right: 2%;
  top: 40%;
  background: white;
  padding: 10px;
  border-radius: 10px;
  z-index: 5;

  @media only screen and (max-width: 768px){
    display: none;
  }
}

.links-container {
  display: flex;
  flex-direction: column;
  isolation: isolate;
}

.link {
  margin: 10px 0;

}

.link-img {
  height: 25px;
  mix-blend-mode: difference;
}

//BANNER

.banner {
  background-color: #fffdfc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 1;

  
  @media only screen and (max-width: 768px){
    flex-direction: column-reverse;
    padding: 300px 0 150px;
    text-align: center;
  }
}

.banner-bg {
  position: absolute;
  z-index: -1;
  height: 220px;
  left: 0;
  bottom: 0px;
  transition:  .5s ease-in;

  @media only screen and (max-width: 768px){
    display: none;
  }

  &--default {
    left: -100px;
    transition:  .5s ease-out;
  }
}

.banner-arrow {
  position: absolute;
  left: 50%;
  bottom: 2%;
  transition: 0.25s ease-in;

  &:hover {
    transition: 0.25s ease-out;
    bottom: 1%;
  }

  @media only screen and (max-width: 768px){
    display: none;
  }
}

.banner-arrow-img {
  height: 50px;
}

.introduction {
  width: 660px;
  font-size: 80px;
  padding: 100px 50px 100px 0;
  font-family: $yellowtail;

  @media only screen and (max-width: 959px){
    width: 100%;
    padding: 50px 10px;
    font-size: 48px;
  }
}

.banner-image {
  height: 500px;

  @media only screen and (max-width: 959px){
    height: auto;
    width: 70%;
  }
}

//SCHOOL

.school {
  background-color: #2D3142;
  padding: 30px 0;
}

.school-container {
  display: flex;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.school-img {
  width: 15%;
  object-fit: contain;
  aspect-ratio: 3/2;
}

//ABOUT

.about {
  background-image: linear-gradient(#fff7ed,#fff7ed, white);
  padding: 50px 0;
  height: 100vh;

  @media only screen and (max-width: 768px){
    height: auto;
  }

  &-img {
    height: 500px;
    position: relative;
    z-index: 2;
    margin: 40px;
    transition:  .3s ease-in;

    @media only screen and (max-width: 959px){
      height: auto;
      width: 100%;
      margin: 0;
    }


    &:hover {
      transition:  .25s ease-out;
      filter: drop-shadow(1px 11px 6px #e57c23);
    }
  }
}

.about-bold {
  font-family: $patua;
  font-size: 48px;

  
  @media only screen and (max-width: 426px){
    font-size: 28px;
  }
}

.about-title {
  font-weight: 700;
  font-size: 42px;
  color: white;
  padding-left: 100px;
}

.about-text-container {
  font-family: $public;
}

.about-description {
  font-size: 24px;
  line-height: 40px;

  @media only screen and (max-width: 426px){
    font-size: 18px;
    line-height: 30px;
  }
}

.about-button-container {
  padding-top: 20px;
  display: flex;
}

.about-counts-container {
  display: flex;
}

.about-counts-box {
  font-family: $patua;
  width: 150px;
}

.about-count {
  font-size: 48px;
  color: #FFB703;
}

.about-count-desc {
  font-size: 24px;
}

.about-button {
  text-decoration: none;
  transition:  .3s ease-in; 
  font-family: $public;
  display: inline-block;
  color: white;
  width: 150px;
  background-color: #4F5D75;
  text-align: center;
  padding: 10px;
  margin-right: 10px;

  &:hover {
    transition:  .3s ease-out;
    color: #4F5D75;
    background-color: white;
  }
}

.about-container {
  display: flex;
  width: 80%;
  margin: auto;
  align-items: center;

  @media only screen and (max-width: 768px){
    flex-direction: column;
  }
}

.about-skills {
  width: 50%;
  position: relative;
}

.about-skills-container {
  display: flex;
}

.about-circle {
  background-color: black;
  width: 100px;
  height: 100px;
  position: relative;
  z-index: 1;
}

//SERVICES

.services {
  background-image: linear-gradient(white,#fff7ed, #fff7ed);
  padding-bottom: 100px;
}

.services-container {
  display: flex;
  margin: auto;
  width: 80%;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px){
    width: 90%;
  }
}

.services-box {
  background-color: #E57C23;
  width: 45%;
  margin: 20px;
  transition: .3s ease-out;
  cursor: pointer;



  &--blue {
    background-color: #4F5D75;
  }

  &--blue.clicked {
    border-color: #4F5D75;
  }

  @media only screen and (max-width: 768px){
    width: 100%;

    .services-box:nth-child(odd) {
      background-color: #E57C23;
    }

    .services-box:nth-child(even) {
      background-color: #4F5D75;
    }
  }

  &--clicked {
    box-shadow: 5px 5px 0px 0px #000000;
    transition:  .3s ease-in; 
    background-color: white;
  }
}

@media only screen and (max-width: 768px){

  .services-box:nth-child(odd) {
    background-color: #E57C23;
  }

  .services-box:nth-child(even) {
    background-color: #4F5D75;
  }

  .services-box--clicked {
    background-color: white !important;
  }
}

.services-line {
  border: 3px solid black;
  height: 175px;
  position: relative;
}

.services-circle {
  height: 10px;
  width: 10px;
  border-radius: 25px;
  box-shadow:inset 0px 0px 0px 10px black;
  margin: auto;
  transition: .3s ease-out; 
  position: absolute;
  left: 49%;
  bottom: 10px;

  &--clicked {
    transition:  .3s ease-in; 
    box-shadow:inset 0px 0px 0px 1px black;
  }
}

.services-desc {
  opacity: 0;
  transition: .4s ease-out; 
  padding: 25px;
  font-size: 18px;
  font-family: $pixel;

  &--show {
    transition:  .4s ease-in; 
    opacity: 1;
    padding: 10px 25px 25px;
  }
}


.services-skill {
  text-align: center;
  color: white;
  font-size: 38px;
  position: relative;
  font-family: $pixel;
  top: 50px;
  transition:  .3s ease-in; 

  &--clicked {
    transition:  .3s ease-out; 
    top: 0;
    color: black;
  }
}

.services-creative {
  height: 200px;
  position: relative;
  bottom: -100px;
  left: 46%;
}

////////////////////// SKILLS
/// 

.skills {
  background-image: linear-gradient(#fff7ed,#fff7ed, white);
  position: relative;
  margin-bottom: 200px;
  padding-top: 50px;

  
  @media only screen and (max-width: 959px){
    margin-bottom: 50px;
  }
}

.skills-bg {
  background-image: linear-gradient(orange, white);
  opacity: .5;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  border-radius: 25px;
}


.skills-container {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 768px){
    flex-direction: column-reverse;
  }
}

.skills-title {
  background-color: rgb(239, 92, 92);
  color: white;
  text-align: center;
  font-size: 72px;
  font-family: $pixel;
  width: 20%;
  padding: 10px;
  box-shadow: -5px 0 0 0 black, 5px 0 0 0 black, 0 -5px 0 0 black, 0 5px 0 0 black, inset -6px -4px 0px 10px rgb(228, 48, 48);
  margin: auto auto 50px;
  position: relative;

  
  @media only screen and (max-width: 768px){
    width: 50%;
    font-size: 60px;
  }
}

.skills-char-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-desc-container {
  text-align: center;
  font-family: $pixel;
}

.skills-char-name {
  font-size: 48px;
}

.skills-button {
  background-color: orange;
  padding: 8px;
  text-decoration: none;
  box-shadow: -5px 0 0 0 black, 5px 0 0 0 black, 0 -5px 0 0 black, 0 5px 0 0 black;
  color: black;
  font-family: $pixel;
  font-size: 24px;
  width: 120px;
  display: inline-block;
  text-align: center; 
  margin: 20px 50px;
  cursor: pointer;

  @media only screen and (max-width: 959px){
    margin: 20px 20px;
  }

  @media only screen and (max-width: 426px){
    margin: 20px 15px;
  }

  
}

.skills-level {
  font-size: 38px;
}

.skills-img {
  height: 450px;

  @media only screen and (max-width: 959px){
    height: auto;
    width: 60%;
  }
}

.skills-small-container {
  @media only screen and (max-width: 959px){
    width: 100%;
  }
}

.skills-box {
  padding: 10px;
  width: 500px;
  position: relative;
  background-color: white;
  box-shadow: -5px 0 0 0 black, 5px 0 0 0 black, 0 -5px 0 0 black, 0 5px 0 0 black;
  font-family: $pixel;
  font-size: 30px;
  margin: 20px 0;
  display: flex;
  overflow: hidden;
  justify-content: space-between;

  @media only screen and (max-width: 959px){
    width: 90%;
    margin: 20px auto;
  }


}

.skill-point {
  position: relative;
  z-index: 2;
}

.skill-desc {
  position: relative;
  z-index: 2;
}

.skill-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translateX(-100%);
  transition: 1s ease-out; 

  &--html {
    background-color: #DD4B25;
  }

  &--css {
    background-color: #264DE4;
  }

  &--js {
    background-color: #F0DC55;
  }

  &--java {
    background-color: #ED272C;
  }

  &--react {
    background-color: #08D9FF;
  }
  &--python {
    background-color: #3A719C;
  }

  &--sql {
    background-color: #DC7939;
  }

  &--unity {
    background-color: #848484;
  }
  
  &.active {
    transform: translateX(0);
    transition: 1s ease-in; 
  }
}

//WORK

.works {
  padding: 50px 0;
  position: relative;
  background-color: #fffdfc;
}

.works-container {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px){
    flex-direction: column;
  }
}

.work-container {
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 50px 0;

  
  @media only screen and (max-width: 768px){
    width: 100%;
    margin: 25px 0;
    
  }

}

.work-desc-big-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  
  @media only screen and (max-width: 768px){ 
    flex-direction: column;
  }
}

.work-desc-container {
  width: 75%;
}

.work-desc-title {
  font-size: 24px;
  font-family: $cabin;
  padding: 10px 0;
  font-weight: 600;
}

.work-description {
  font-size: 18px;
  font-family: $pixel;
}

.work-link {
  color: black;
  text-decoration: none;
  font-family: $public;
  background: orange;
  padding: 10px;
  color: white;
  border-radius: 25px;
  transition: 0.2s ease-in;
  white-space: nowrap;

  &:hover {
    transition: 0.2s ease-out;
    background: rgb(212, 138, 0);
  }
}


.work-status-container {
  text-align: end;

  @media only screen and (max-width: 768px){
    margin: 20px;
  }

}



.work-img {
  width: 100%;
  display: flex;
  background-size: cover;
  height: 300px;
  border-radius: 15px;
  transition: 0.2s ease-in;
  position: relative;
  top: 0;

  &:hover {
    box-shadow: 9px 8px 4px 1px #000000;
    transition: 0.2s ease-out;
    top: -10px;
  }

  @media only screen and (max-width: 426px){
    height: 180px;
  }
}


//TITLE

.title-title {
  font-family: $kanit;
  font-size: 60px;
  padding: 0 1%;
  color: #1D3557;;
  background: white;
  z-index: 1;
  position: relative;
  text-align: center;
}

.title-hr {
  padding-top: 20;
  border-top: 5px dotted black;
  position: relative;
  top: 72px;
}




//FOOTER


.footer-call {
  font-size: 60px;
  font-family: $public;

  @media only screen and (max-width: 768px){
    font-size: 36px;
    padding: 10px 0;
  }
}

.footer-desc {
  font-size: 24px;
  margin-bottom: 80px;
  font-family: $cabin;

  @media only screen and (max-width: 768px){
    font-size: 18px;
    margin-bottom: 40px;
  }
}

.footer-img {
  position: absolute;
  height: 280px;
  right: 200px;
  top: 0;
  z-index: -1;
  transition: 0.25s ease-in;

  
  @media only screen and (max-width: 768px){
    display: none;
  }

  &--hover {
    right: -124px;
    transition:  0.25s ease-out;

  }

  &--hand {
    right: -124px;
    z-index: 0;
  }
}

.footer-container {
  text-align: center;
  border-radius: 20px;
  background-color: #ff5151;
  padding: 100px 0 150px;
  margin: 2% 20%;
  color: white;
  position: relative;

  @media only screen and (max-width: 768px){
    margin: 0;
    padding: 30px 20px 60px;
    border-radius: 0;
    
  }
}

.footer-email {
  border: 2px solid white;
  padding: 25px 25px;
  color: white;
  text-decoration: none;
  font-family: $cabin;

  @media only screen and (max-width: 768px){
    padding: 10px 25px;
  }

  &:hover {
    background-color: white;
    color: #ff5151;
  }
}

.copyright {
  font-family: $public;
  padding: 15px 0;
  color: white;
  background: #1D3557;
  text-align: center;
}